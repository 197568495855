<template>
  <div>
    <app-breadcrumb/>
    <div class="page-container">
      <template>
        <el-tabs v-model="activeName">
          <el-tab-pane label="基本信息" name="first">
            <el-form class="DisNoCss" :model="listQuery" label-width="130px">
              <el-form-item label="代办处编号:" v-if="isAgent">
                {{ this.listQuery.agentNo }}
              </el-form-item>
              <el-form-item :label="this.listQuery.customerType === '2' ? '经销商编号:':'顾客编号:'">
                {{ this.listQuery.memberNo }}
              </el-form-item>
              <el-form-item label="顾客姓名:">
                {{ this.listQuery.memberName }}
              </el-form-item>
              <el-form-item label="级别:">
                <span v-if="this.listQuery.customerType === '0'">普通顾客</span>
                <span v-if="this.listQuery.customerType === '1'">优惠顾客</span>
                <span v-if="this.listQuery.customerType === '2'">经销商</span>
                {{ $lt(initDict.level, this.listQuery.levelType) }}
              </el-form-item>
<!--              <el-form-item label="会员级别:">-->
<!--                {{ $lt(initDict.level, this.listQuery.customerType) }}-->
<!--              </el-form-item>-->
              <el-form-item label="荣衔:">
                {{ $lt(initDict.honor, this.listQuery.cardTypeH) }}
              </el-form-item>
<!--              <el-form-item label="状态:">-->
<!--                {{ $lt(initDict.statu, this.listQuery.status) }}-->
<!--              </el-form-item>-->
              <el-form-item label="证件类型:">
                {{ $lt(initDict.paper, this.listQuery.paperType) }}
              </el-form-item>
              <el-form-item label="证件号:">
                {{ this.listQuery.paperNo }}
              </el-form-item>
              <el-form-item label="昵称:">
                <el-input
                    v-model.trim="listQuery.petName"
                    size="mini"
                    style="width: 300px"
                    :disabled="disabled"
                    placeholder="昵称"
                />
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
        <el-tabs class="DisNoCss" v-model="activeName">
          <el-tab-pane label="收货信息" name="first">
            <el-form label-width="130px">
              <el-form-item v-if="disabled" label="所在地区:">
                 中国 - {{ listQuery.addrProvince }} - {{ listQuery.addrCity }} -{{ listQuery.addrDistrict }}
              </el-form-item>
              <el-form-item v-if="!disabled" label="所在地区:">
                <el-col :span="6">
                  <el-select
                      v-model="countrymodel"
                      value-key="regionId"
                      placeholder="请选择国家"
                      clearable
                      size="mini"
                      @change="countrychange"
                  >
                    <el-option
                        v-for="item in addchoicecountry"
                        :key="item.regionId"
                        :label="item.regionName"
                        :value="item"
                    />
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-select
                      v-model="provincemodel"
                      value-key="regionId"
                      placeholder="请选择省份"
                      clearable
                      size="mini"
                      @change="provincechange"
                  >
                    <el-option
                        v-for="item in addchoiceprovince"
                        :key="item.regionId"
                        :label="item.regionName"
                        :value="item"
                    />
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-select
                      v-model="citymodel"
                      value-key="regionId"
                      placeholder="请选择市"
                      clearable
                      size="mini"
                      @change="citychange"
                  >
                    <el-option
                        v-for="item in addchoicecity"
                        :key="item.regionId"
                        :label="item.regionName"
                        :value="item"
                    />
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-select v-model="areamodel" :disabled="disabled" size="mini" placeholder="请选择区/县" clearable>
                    <el-option
                        v-for="item in addchoicearea"
                        :key="item.regionId"
                        placeholder="请选择区/县"
                        :label="item.regionName"
                        :value="item.regionCode"
                    />
                  </el-select>
                </el-col>
              </el-form-item>
              <el-form-item label="详细地址:">
                <el-input
                    v-model.trim="listQuery.storeAddr"
                    size="mini"
                    style="width: 300px"
                    :disabled="disabled"
                    placeholder="详细地址"
                />
              </el-form-item>
              <el-form-item label="邮政编码:">
                <el-input
                    v-model.trim="listQuery.storePost"
                    size="mini"
                    style="width: 300px"
                    :disabled="disabled"
                    placeholder="邮政编码"
                />
              </el-form-item>
              <el-form-item label="移动电话:">
                {{ listQuery.mobile }}
                <el-button
                    v-show="determine1"
                    :disabled="sendMessageAble"
                    :loading="btnloading"
                    size="mini"
                    :type="btntype"
                    @click="sendMessage"
                    style="margin-left: 20px"
                >{{ btnname }}
                </el-button>
              </el-form-item>
              <el-form-item label="验证码:">
                <el-input
                    v-model.trim="listQuery.sendcaptcha"
                    size="mini"
                    style="width: 300px"
                    :disabled="disabled"
                    placeholder="验证码"
                />
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
        <el-tabs v-model="activeName">
          <el-tab-pane label="银行账户信息" name="first">
            <el-form label-width="130px">
              <el-form-item label="开户银行:">
                {{ this.listQuery.accountBank }}
              </el-form-item>
              <el-form-item label="户名:">
                {{ this.listQuery.accountName }}
              </el-form-item>
              <el-form-item label="账号:">
                {{ this.listQuery.accountCode }}
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
        <div style="margin-bottom: 10px" v-if="this.listQuery.cardTypeH && this.listQuery.cardTypeH >= '4'">
          <el-tabs v-model="activeName">
            <el-tab-pane label="贡献值" name="first">
              <el-row :gutter="20">
                <el-col style="margin: 5px 0" :spam="24">
                  <el-button class="iconfont icon-zuanshi_o" type="primary" size="mini" @click="dividendsBtn('S1')"></el-button>
                </el-col>
                <el-col style="margin: 5px 0" :spam="24">
                  <el-button type="primary" size="medium" @click="dividendsBtn('B1')">1</el-button>
                </el-col>
                <el-col style="margin: 5px 0" :spam="24">
                  <el-button type="primary" size="medium" @click="dividendsBtn('B2')">3</el-button>
                </el-col>
                <el-col style="margin: 5px 0" :spam="24">
                  <el-button type="primary" size="medium" @click="dividendsBtn('B3')">5</el-button>
                </el-col>
                <el-col style="margin: 5px 0" :spam="24">
                  <el-button type="primary" size="medium" @click="dividendsBtn('B4')">7</el-button>
                </el-col>
                <el-col style="margin: 5px 0" :spam="24">
                  <el-button type="primary" size="medium" @click="dividendsBtn('S2')">9</el-button>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </div>
      </template>
      <div style="margin-left: 40%">
        <el-button v-show="modify" icon="el-icon-edit-outline" type="primary" @click="modifyedit">修改</el-button>
        <el-button v-show="determine1" icon="el-icon-circle-check" type="primary" @click="determine">确定</el-button>
        <el-button v-show="determine1" icon="el-icon-circle-close" type="primary" @click="close">取消</el-button>
      </div>
      <el-dialog
              :visible.sync="dividendsDialog"
              :close-on-click-modal="false"
              :before-close="handleCloseview">
        <el-table
                :data="dividendsList"
                border
                fit
                highlight-current-row
                style="width: 100%;"
        >
          <el-table-column label="创建时间" prop="createdTime" align="center" />
          <el-table-column label="贡献值" prop="validBalance" align="center" />
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="closureDividends">关闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import '../../../assets/iocn/zuanshi/iconfont.css'
import '../../../assets/iocn/zuanshi/iconfont.js'
import {
  fetChildRegions,
  getAlRegionsIdList,
  memberProfileModify,
  memberProfileModifyEdit,
  memberSendVerification,
  memberAccountTurnover
} from '@/api/home'
import {mapGetters} from "vuex";
export default {
  name: 'MemberProfile',
  data() {
    return {
      viewType: '',
      dividendsview: {},
      dividendsList: [],
      dividendsDialog: false,
      dividendsType: '',
      time: 60,
      btnloading: false,
      btnname: '发送',
      btntype: 'danger',
      sendMessageAble: true,
      modify: true,
      determine1: false,
      disabled: true,
      regionId: 0,
      // ----------------------------------------------
      countrymodel2: '', // 国家要传code值
      provincemodel2: '', // 省要传code值
      citymodel2: '', // 市要传code值
      countrymodel: '', // 新增选择国家model
      provincemodel: '', // 新增选择省存储model
      citymodel: '', // 新增选择市存储model
      areamodel: '', // 新增选择县存储model
      // -----------------------------------------------------
      addchoicecountry: [], // 新增选择国家存储
      addchoiceprovince: [], // 新增选择省存储
      addchoicecity: [], // 新增选择市存储
      addchoicearea: [], // 新增选择县存储
      // --------------------------------------
      wWeekLoading: false,
      activeName: 'first',
      listQuery: {
        agentNo: null,
        memberNo: null,
        memberName: null,
        levelType: null,
        customerType: null,
        cardType: null,
        cardTypeH:null,
        status: null,
        paperType: null,
        paperNo: null,
        petName: null,
        countrymodel: null,
        provincemodel: null,
        citymodel: null,
        areamodel: null,
        storeAddr: null,
        storePost: null,
        mobile: '',
        accountBank: null,
        accountName: null,
        accountCode: null,
        sendcaptcha: null
      },
      initDict: {
        level: 'cn.member.level',
        customer: 'customer_type',
        honor: 'cn.mimember.cardtype_net',
        statu: 'mimember.status',
        paper: 'miagent.papertype'
      }
    }
  },
  computed: {
    ...mapGetters([
      'isAgent',
      'userCode',
      'permissions'
    ]),
    mobile() {
      return this.listQuery.mobile
    },
    levels() {
      return this.$ll(this.initDict.level)
    },
    customers() {
      return this.$ll(this.initDict.customer)
    },
    honors() {
      return this.$ll(this.initDict.honor)
    },
    status() {
      return this.$ll(this.initDict.statu)
    },
    papers() {
      return this.$ll(this.initDict.paper)
    },
  },
  watch: {
    mobile(val) {
      this.sendMessageAble = val.toString().length !== 11;
      val.toString().length === 11 ? this.btntype = 'success' : this.btntype = 'danger'
    }
  },
  created() {
    this.getList()
    this.zerochange()
  },
  methods: {
    handleCloseview(done) { // x关闭按钮
      done()
      this.viewType = ''
      this.dividendsList = []
    },
    closureDividends() {
      this.dividendsDialog = false
      this.viewType = ''
      this.dividendsList = []
    },
    // 查看详情里面的分红,基金查看按钮
    dividendsBtn(type) {
      this.dividendsList = []
      this.viewType = type
      this.dividendsDialog = true
      this.dividendsview.type = this.viewType
      memberAccountTurnover(this.dividendsview).then(res => {
        this.dividendsList = res.data.records
        let sumObj ={
          createdTime:'合计',
          validBalance:null
        }
        if(this.dividendsList&&this.dividendsList.length > 0){
          for(let i=0;i < this.dividendsList.length;i++){
            sumObj.validBalance +=this.dividendsList[i].validBalance
          }
          this.dividendsList.push(sumObj)
        }
      })
    },
    async sendMessage() {
      // 校验手机号是否为空
      if (!this.listQuery.mobile) {
        this.$message.error('请输入手机号');
        return;
      }

      try {
        // 初始化按钮状态
        this.initButtonState();

        // 调用发送验证码接口
        await memberSendVerification({ mobile: this.listQuery.mobile.toString() });

        // 启动倒计时
        this.startCountdown();
      } catch (error) {
        // 异常处理
        this.$message.error('发送验证码失败，请稍后再试');
        this.resetButtonState();
      }
    },

    initButtonState() {
      this.btnloading = true;
      this.btntype = 'primary';
      this.btnname = '发送中...';
    },

    startCountdown() {
      let intervalId = null;
      this.time = Math.max(60, parseInt(this.time, 10)); // 确保 time 是有效的正整数

      intervalId = setInterval(() => {
        if (this.time > 0) {
          this.btnname = `（${this.time}秒）后重新发送`;
          --this.time;
        } else {
          this.resetButtonState();
          clearInterval(intervalId);
        }
      }, 1000);

      // 在组件销毁时清理定时器
      this.$once('hook:beforeDestroy', () => {
        clearInterval(intervalId);
      });
    },

    resetButtonState() {
      this.btnloading = false;
      this.btntype = 'success';
      this.btnname = '重新发送';
      this.time = 60;
    }
    ,
    getList() {
      memberProfileModify().then(res => {
        this.listQuery = res.data
        this.listQuery.regionCode = res.data.regionList[0].regionCode
        this.doareagetList()
      }).catch(() => {
      })
    },
    modifyedit() {
      this.modify = false
      this.disabled = false
      this.determine1 = true
      fetChildRegions({'regionId': 0}).then(res => {
        this.addchoicecountry = res.data
      }).catch(() => {
      })
    },
    determine() {
      if (!this.doArea()) {
        return this.$message.error('请填写地区')
      }
      if (this.listQuery.sendcaptcha) {
        memberProfileModifyEdit({...this.listQuery}).then(() => {
          this.modify = true
          this.disabled = true
          this.determine1 = false
          this.getList()
        }).catch(() => {
        })
      } else {
        this.$message.error('请输入验证码')
      }
    },
    close() {
      this.modify = true
      this.disabled = true
      this.determine1 = false
      this.getList()
    },
    // 编辑返回地区
    doareagetList() {
      getAlRegionsIdList({'regionCode': this.listQuery.regionCode}).then(res => {
        const areaList = res.data
        areaList.reverse()
        this.countrymodel = areaList[0].regionName
        if (areaList[1]) {
          this.provincemodel = areaList[1]
          fetChildRegions({'regionId': res.data[0].regionId}).then(resa => {
            this.addchoiceprovince = resa.data
          })
        }
        if (areaList[2]) {
          this.citymodel = areaList[2]
          fetChildRegions({'regionId': res.data[1].regionId}).then(resa => {
            this.addchoicecity = resa.data
          })
        }
        if (areaList[3]) {
          this.areamodel = areaList[3].regionCode
          fetChildRegions({'regionId': res.data[2].regionId}).then(resa => {
            this.addchoicearea = resa.data
          })
        }
      }).catch(() => {
      })
    },
    // 所在地区,  需要created的
    zerochange() {
      fetChildRegions({'regionId': 0}).then(res => {
        this.addchoicecountry = res.data
      })
    },
    // 新增按钮 - 国家change事件
    countrychange(val) {
      // 选择国家后 省, 市, 区清空
      this.provincemodel = ''
      this.citymodel = ''
      this.areamodel = ''
      this.countrymodel2 = val.regionCode
      fetChildRegions({'regionId': val.regionId}).then(res => {
        this.addchoiceprovince = res.data
      }).catch(() => {
      })
    },
    // 新增按钮 - 省change事件
    provincechange(val) {
      // 选择省后  市, 区清空
      this.citymodel = ''
      this.areamodel = ''
      this.provincemodel2 = val.regionCode
      fetChildRegions({'regionId': val.regionId}).then(res => {
        this.addchoicecity = res.data
      }).catch(() => {
      })
    },
    // 新增按钮 - 市change事件,
    citychange(val) {
      // 选择市后 区清空
      this.areamodel = ''
      this.citymodel2 = val.regionCode
      fetChildRegions({'regionId': val.regionId}).then(res => {
        this.addchoicearea = res.data
      }).catch(() => {
      })
    },
    // 判断要提交的数据
    doArea() {
      // 判断区, 在 市 省 国
      if (this.areamodel) {
        this.listQuery.regionCode = this.areamodel
        return true
      } else if (this.citymodel) {
        this.listQuery.regionCode = this.citymodel2.regionCode
        return true
      } else if (this.provincemodel) {
        this.listQuery.regionCode = this.provincemodel2.regionCode
        return true
      } else if (this.countrymodel) {
        this.listQuery.regionCode = this.countrymodel2.regionCode
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .DisNoCss {
    ::v-deep .el-input__inner{
      background-color: white;
      color: black;
    }
    ::v-deep .el-textarea__inner{
      background-color: white;
      color: black;
    }
  }
</style>
